body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  overflow-x: hidden;
}

@font-face {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/arial.ttf');
}

@font-face {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/calibri.ttf');
}

@font-face {
  font-family: 'Cambria';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/cambria.ttf');
}

@font-face {
  font-family: 'Courier New';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/courier-new.ttf');
}

@font-face {
  font-family: 'Times New Roman';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/times-new-roman.ttf');
}

@font-face {
  font-family: 'Dextor D';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/dextor-d.ttf');
}

@font-face {
  font-family: 'Dextor Out D';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/dextor-out-d.ttf');
}

@font-face {
  font-family: 'Nila';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/nila.ttf');
}

@font-face {
  font-family: 'Nirmala';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/nirmala.ttf');
}

@font-face {
  font-family: 'Nueva';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/nueva.ttf');
}

@font-face {
  font-family: 'Sim Sun';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/sim-sun.ttf');
}

@font-face {
  font-family: 'The Northern Block';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/the-northern-block.ttf');
}